import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Logging contextual information";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RequiredDependencies = makeShortcode("RequiredDependencies");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "logging-contextual-information",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#logging-contextual-information",
        "aria-label": "logging contextual information permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Logging contextual information`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#built-in-properties"
          }}>{`Built-in properties`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#http-request-and-response-headers"
          }}>{`HTTP request and response headers`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#custom-attributes"
          }}>{`Custom attributes`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#using-an-alternative-string-converter-for-a-custom-attribute"
            }}>{`Using an alternative string converter for a custom attribute`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#customizing-mdc-keys"
          }}>{`Customizing MDC keys`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#specifying-a-prefix-for-mdc-keys"
            }}>{`Specifying a prefix for MDC keys`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`With Armeria's `}<a parentName="p" {...{
        "href": "https://logback.qos.ch/"
      }}>{`Logback`}</a>{` integration, you can log the properties of the
`}<a parentName="p" {...{
        "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
      }}>{`type://RequestContext`}</a>{` of the request being handled. `}<a parentName="p" {...{
        "href": "type://RequestContextExportingAppender:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logback/RequestContextExportingAppender.html"
      }}>{`type://RequestContextExportingAppender`}</a>{` is
a Logback appender that exports the properties of the current `}<a parentName="p" {...{
        "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
      }}>{`type://RequestContext`}</a>{` to
`}<a parentName="p" {...{
        "href": "https://logback.qos.ch/manual/mdc.html"
      }}>{`MDC`}</a>{` (mapped diagnostic context).`}</p>
    <p>{`First, you need the `}<inlineCode parentName="p">{`armeria-logback`}</inlineCode>{` dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-logback'
    }]} mdxType="RequiredDependencies" />
    <p>{`Then, let's look at the following example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<?xml version="1.0" encoding="UTF-8"?>
<configuration>
  <appender name="CONSOLE" class="ch.qos.logback.core.ConsoleAppender">
    <encoder>
      <pattern>%d{HH:mm:ss.SSS} %X{remote.ip} %X{tls.cipher}
               %X{req.headers.user-agent} %X{attrs.some_value} %msg%n</pattern>
    </encoder>
  </appender>

  <appender name="RCEA" class="com.linecorp.armeria.common.logback.RequestContextExportingAppender">
    <appender-ref ref="CONSOLE" />
    <export>remote.ip</export>
    <export>tls.cipher</export>
    <export>req.headers.user-agent</export>
    <export>attrs.some_value:com.example.AttrKeys#SOME_KEY</export>
    <!-- ... or alternatively:
    <exports>remote.ip, remote.port, tls.cipher,
             req.headers.user-agent,
             attrs.some_value:com.example.AttrKeys#SOME_KEY</exports>
    -->
    <!-- ... or with wildcard:
    <export>req.*</export>
    -->
    <!-- ... or with custom MDC key:
    <export>remote_id=remote.id</export>
    <export>UA=req.headers.user-agent</export>
    <export>some_value=attr:com.example.AttrKeys#SOME_KEY</export>
    -->
  </appender>
  ...
</configuration>
`}</code></pre>
    <p>{`The above configuration defines an appender called `}<inlineCode parentName="p">{`RCEA`}</inlineCode>{` which exports the following:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`remote.ip`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`the IP address of the remote peer,`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`tls.cipher`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`the SSL/TLS cipher suite of the connection,`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`req.headers.user-agent`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`the user agent of the client,`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`attrs.some_value`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`a custom attribute set via `}<inlineCode parentName="li">{`RequestContext.setAttr(AttributeKey.valueOf(AttrKeys.class, "SOME_KEY"), "SOME_VALUE")`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <p>{`... to the `}<a parentName="p" {...{
        "href": "https://logback.qos.ch/manual/mdc.html"
      }}>{`MDC`}</a>{` property map and forwards the log message to the appender `}<inlineCode parentName="p">{`CONSOLE`}</inlineCode>{`, as defined in the
`}<inlineCode parentName="p">{`<appender-ref />`}</inlineCode>{` element.`}</p>
    <p>{`There are three types of properties you can export using `}<a parentName="p" {...{
        "href": "type://RequestContextExportingAppender:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logback/RequestContextExportingAppender.html"
      }}>{`type://RequestContextExportingAppender`}</a>{`.`}</p>
    <h2 {...{
      "id": "built-in-properties",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#built-in-properties",
        "aria-label": "built in properties permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Built-in properties`}</h2>
    <p>{`A built-in property is a common property available for most requests. See the complete list of the built-in
properties and their MDC keys at `}<a parentName="p" {...{
        "href": "type://BuiltInProperty:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/BuiltInProperty.html"
      }}>{`type://BuiltInProperty`}</a>{`.
You can also use wildcard character `}<inlineCode parentName="p">{`*`}</inlineCode>{` instead of listing all properties. For example:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`"*"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`"req.*"`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "http-request-and-response-headers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#http-request-and-response-headers",
        "aria-label": "http request and response headers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTTP request and response headers`}</h2>
    <p>{`When the session protocol of the current connection is HTTP, a user can export HTTP headers of the current
request and response. The MDC key of the exported header is `}<inlineCode parentName="p">{`"req.headers.<lower-case header name>"`}</inlineCode>{` or
`}<inlineCode parentName="p">{`"res.headers.<lower-case header name>"`}</inlineCode>{`. For example:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`"req.headers.user-agent"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`"res.headers.set-cookie"`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "custom-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#custom-attributes",
        "aria-label": "custom attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Custom attributes`}</h2>
    <p>{`A user can attach an arbitrary custom attribute to a `}<a parentName="p" {...{
        "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
      }}>{`type://RequestContext`}</a>{` by using
`}<a parentName="p" {...{
        "href": "/docs/advanced-custom-attributes"
      }}>{`RequestContext custom attributes`}</a>{` to store the information associated with the
request being handled.
`}<a parentName="p" {...{
        "href": "type://RequestContextExportingAppender:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logback/RequestContextExportingAppender.html"
      }}>{`type://RequestContextExportingAppender`}</a>{` can export such attributes to the `}<a parentName="p" {...{
        "href": "https://logback.qos.ch/manual/mdc.html"
      }}>{`MDC`}</a>{` property map as well.`}</p>
    <p>{`Unlike other property types, you need to specify the full name of an attribute as well as its alias.
For example, if you want to export an attribute `}<inlineCode parentName="p">{`com.example.Foo#ATTR_BAR`}</inlineCode>{` with the alias `}<inlineCode parentName="p">{`bar`}</inlineCode>{`, you need to add
`}<inlineCode parentName="p">{`<export>attrs.bar:com.example.Foo#ATTR_BAR</export>`}</inlineCode>{` to the XML configuration. The resulting MDC key to
access the attribute value is `}<inlineCode parentName="p">{`attrs.bar`}</inlineCode>{`, which follows the form of `}<inlineCode parentName="p">{`attrs.<alias>`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "using-an-alternative-string-converter-for-a-custom-attribute",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-an-alternative-string-converter-for-a-custom-attribute",
        "aria-label": "using an alternative string converter for a custom attribute permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using an alternative string converter for a custom attribute`}</h3>
    <p>{`By default, `}<a parentName="p" {...{
        "href": "type://RequestContextExportingAppender:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logback/RequestContextExportingAppender.html"
      }}>{`type://RequestContextExportingAppender`}</a>{` uses `}<inlineCode parentName="p">{`Object.toString()`}</inlineCode>{` to convert an attribute value
into an `}<a parentName="p" {...{
        "href": "https://logback.qos.ch/manual/mdc.html"
      }}>{`MDC`}</a>{` value. If you want an alternative string representation of an attribute value, you can define
a `}<inlineCode parentName="p">{`Function`}</inlineCode>{` class with a public no-args constructor that transforms an attribute value into a `}<inlineCode parentName="p">{`String`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`package com.example;

public class SomeValue {
    public final String value;

    @Override
    public String toString() {
        // Too verbose for logging
        return "SomeValue(value=" + value + ')';
    }
}

public class MyStringifier implements Function<SomeValue, String> {
    @Override
    public String apply(SomeValue o) {
        return o.value;
    }
}
`}</code></pre>
    <p>{`Once the `}<inlineCode parentName="p">{`Function`}</inlineCode>{` is implemented, specify the fully-qualified class name of the `}<inlineCode parentName="p">{`Function`}</inlineCode>{` implementation
as the 3rd component of the `}<inlineCode parentName="p">{`<export />`}</inlineCode>{` element in the XML configuration:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<?xml version="1.0" encoding="UTF-8"?>
<configuration>
  ...
  <appender name="RCEA" class="com.linecorp.armeria.common.logback.RequestContextExportingAppender">
    ...
    <export>attrs.some_value:com.example.AttrKeys#SOME_KEY:com.example.MyStringifier</export>
    ...
  </appender>
  ...
</configuration>
`}</code></pre>
    <h2 {...{
      "id": "customizing-mdc-keys",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#customizing-mdc-keys",
        "aria-label": "customizing mdc keys permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Customizing MDC keys`}</h2>
    <p>{`You can override the pre-defined MDC key by prepending an alias and an equals sign (=) to it.
For example, if you want to change `}<inlineCode parentName="p">{`req.id`}</inlineCode>{` to `}<inlineCode parentName="p">{`request_id`}</inlineCode>{`, use `}<inlineCode parentName="p">{`request_id=req.id`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<?xml version="1.0" encoding="UTF-8"?>
<configuration>
  ...
  <appender name="RCEA" class="com.linecorp.armeria.common.logback.RequestContextExportingAppender">
    ...
    <export>remote_id=remote.id</export>
    <export>UA=req.headers.user-agent</export>
    <export>some_value=attr:com.example.AttrKeys#SOME_KEY</export>
    ...
  </appender>
  ...
</configuration>
`}</code></pre>
    <p>{`Note that a custom MDC key cannot be used with a wildcard expression `}<inlineCode parentName="p">{`*`}</inlineCode>{` or `}<inlineCode parentName="p">{`req.*`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "specifying-a-prefix-for-mdc-keys",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#specifying-a-prefix-for-mdc-keys",
        "aria-label": "specifying a prefix for mdc keys permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Specifying a prefix for MDC keys`}</h3>
    <p>{`You can specify a prefix for MDC keys using the `}<inlineCode parentName="p">{`<prefix>`}</inlineCode>{` element.
If you want to add a prefix `}<inlineCode parentName="p">{`armeria.`}</inlineCode>{` to all MDC keys,
you need to add `}<inlineCode parentName="p">{`<prefix>armeria.</prefix>`}</inlineCode>{` to the XML configuration.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<?xml version="1.0" encoding="UTF-8"?>
<configuration>
  ...
  <appender name="RCEA" class="com.linecorp.armeria.common.logback.RequestContextExportingAppender">
    ...
    <!-- set the prefix of MDC keys -->
    <prefix>armeria.</prefix>
    <export>remote.id</export>
    <export>req.headers.user-agent</export>
    <export>some_value=attr:com.example.AttrKeys#SOME_KEY</export>
    ...
  </appender>
  ...
</configuration>
`}</code></pre>
    <p>{`When you want to specify a different prefix, you can define `}<inlineCode parentName="p">{`<prefix>`}</inlineCode>{`, `}<inlineCode parentName="p">{`<export>`}</inlineCode>{`, and `}<inlineCode parentName="p">{`<exports>`}</inlineCode>{` elements
in the `}<inlineCode parentName="p">{`<exportGroup>`}</inlineCode>{` element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<?xml version="1.0" encoding="UTF-8"?>
<configuration>
  ...
  <appender name="RCEA" class="com.linecorp.armeria.common.logback.RequestContextExportingAppender">
    ...
    <!-- set the prefix of exports which is not wrapped with the <exportGroup> element -->
    <prefix>armeria.</prefix>
    <export>remote.id</export>
    <export>req.headers.user-agent</export>
    ...
    <exportGroup>
      <!-- set the prefix of exports in this <exportGroup> -->
      <prefix>some_prefix.</prefix>
      <export>some_value=attr:com.example.AttrKeys#SOME_KEY</export>
      ...
    </exportGroup>
    <exportGroup>
      <!-- if <prefix> is not defined, no prefix is added to exports -->
      <export>tracking_id=attr:com.example.AttrKeys#TRACKING_ID_KEY</export>
      ...
    </exportGroup>
  </appender>
  ...
</configuration>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      